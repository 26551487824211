import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {CheckboxWrap, CheckboxItemWrap, CheckboxBg, CheckboxBody} from './checkbox.style';


//codepen link - https://codepen.io/webopixel/pen/zomRoX

export const Checkbox = forwardRef(({id, labelText, ...rest}, ref) => {
    return (
        <CheckboxWrap>
            <CheckboxItemWrap>
                <input
                    type="checkbox"
                    id={id}
                    name={id}
                    ref={ref}
                    {...rest}
                />
                {/* <CheckboxBg/>
                <CheckboxBody>
                    
                </CheckboxBody> */}
          <label htmlFor={id}>{labelText}</label>{" "}
          </CheckboxItemWrap>
        </CheckboxWrap>
    )
})

/* Checkbox.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
    ]),
    name: PropTypes.string,
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onChange: PropTypes.func,
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    pt: PropTypes.string,
    pb: PropTypes.string,
    pl: PropTypes.string,
    pr: PropTypes.string,
    color: PropTypes.string,
    fontsize: PropTypes.string,
    fontweight: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    borderwidth: PropTypes.string,
    bordercolor: PropTypes.string,
    bordertopcolor: PropTypes.string,
    borderbottomcolor: PropTypes.string,
    borderleftcolor: PropTypes.string,
    borderrightcolor: PropTypes.string
}

Checkbox.defaultProps = {
    name: 'checkbox',
    id: 'checkbox',
    height: '56px',
    pt: '0',
    pb: '0',
    pl: '20px',
    pr: '20px',
    color: '#000',
    bgcolor: '#f8f8f8',
    fontsize: '15px',
    fontweight: 400,
    borderradius: '5px',
    borderwidth: '1px',
    bordercolor: '#f8f8f8',
    bordertopcolor: 'transparent',
    borderbottomcolor: 'transparent',
    borderleftcolor: 'transparent',
    borderrightcolor: 'transparent',
    hover: "true"
} */
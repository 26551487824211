import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import parse from "html-react-parser";
import { Container } from "react-bootstrap";
import Logo from "../../../../components/logo";
//import Logo from "../../../../assets/images/logo/dark-logo.png";
import { Row, Col } from "../../../../components/ui/wrapper";
import Text from "../../../../components/ui/text";
import Anchor from "../../../../components/ui/anchor";
import Heading from "../../../../components/ui/heading";
import Social, { SocialLink } from "../../../../components/ui/social";
import {
  FooterWrap,
  FooterTop,
  FooterWidget,
  LogoWidget,
  TextWidget,
  FooterWidgetList,
  FooterBottom
} from "./footer.style";

const Footer = ({ copyrightStyle, ...props }) => {
  const siteInfo = useStaticQuery(graphql`
    query FooterSiteQuery {
      site {
        siteMetadata {
          copyright
          contact {
            phone
            email
            address {
              line1,
              line2,
              locality,
              city,
              country,
              pin
            }
            website
          }
          social {
            facebook
            twitter
            linkedin
          }
        }
      }
    }
  `);
  const { phone, email, address, website } = siteInfo.site.siteMetadata.contact;
  const { copyright } = siteInfo.site.siteMetadata;
  const {
    facebook,
    twitter,
    instagram,
    linkedin
  } = siteInfo.site.siteMetadata.social;
  return (
    <FooterWrap {...props}>
      <FooterTop>
        <Container>
          <Row>
            <Col lg={5} sm={6}>
              <FooterWidget responsive={{ medium: { mb: "31px" } }}>
                {/* <LogoWidget>
                  <img src={Logo} alt="Logo" />
                </LogoWidget> */}
                <Logo darkLogo width={300} height={90}/>
                <TextWidget >
                  {/* {
                    address && (
                      <>
                        <Text mb="10px" mr="40px">{address.line1},</Text>
                        <Text mb="10px" mr="40px">{address.line2}</Text>
                        <Text mb="10px" mr="40px">{address.locality}, {address.city}</Text>
                        <Text mb="10px" mr="40px">{address.country} - {address.pin}</Text>
                      </>
                    )
                  } */}
                  {email && (
                    <Text mb="10px">
                      <Anchor
                        path={`mailto:${email}`}
                        color="textColor"
                        fontWeight="800"
                        hoverstyle="2"
                      >
                        {email}
                      </Anchor>
                    </Text>
                  )}
                  {/* {phone && (
                    <Text mb="10px">
                      <Anchor
                        path={`tel:${phone}`}
                        fontWeight="800"
                        color="#333"
                        hoverstyle="2"
                      >
                        {phone}
                      </Anchor>
                    </Text>
                  )} */}
                  {website && (
                    <Text mb="10px">
                      <Anchor path={website} hoverstyle="2">
                        {website}
                      </Anchor>
                    </Text>
                  )}
                </TextWidget>
              </FooterWidget>
            </Col>
            <Col lg={3} md={5} sm={6}>
              <FooterWidget responsive={{ medium: { mb: "31px" } }}>
                <Heading as="h6" mt="-3px" mb="20px">
                  Services
                </Heading>
                <FooterWidgetList>
                  <li>
                    <Anchor path="/services/web" color="textColor" hoverstyle="2">
                      Web
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/services/mobile" color="textColor" hoverstyle="2">
                      Mobile
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/services/cloud" color="textColor" hoverstyle="2">
                      Cloud
                    </Anchor>
                  </li>
                </FooterWidgetList>
              </FooterWidget>
            </Col>
            <Col lg={3} md={5} sm={6}>
              <FooterWidget responsive={{ medium: { mb: "27px" } }}>
                <Heading as="h6" mt="-3px" mb="20px">
                  Quick links
                </Heading>
                <FooterWidgetList>
                  <li>
                    <Anchor path="/about-us" color="textColor" hoverstyle="2">
                      About Us
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/case-studies" color="textColor" hoverstyle="2">
                    Case Studies
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/careers" color="textColor" hoverstyle="2">
                      Careers
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/free-consultation" color="textColor" hoverstyle="2">
                      Free Estimate
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/contact-us" color="textColor" hoverstyle="2">
                      Contact
                    </Anchor>
                  </li>
                </FooterWidgetList>
              </FooterWidget>
            </Col>
          </Row>
        </Container>
      </FooterTop>
      <FooterBottom>
        <Container>
          <Row className="align-items-center">
            <Col md={6} className="text-center text-md-left">
              {copyright && (
                <Text {...copyrightStyle}>
                  &copy; {new Date().getFullYear()} {parse(copyright)}
                </Text>
              )}
            </Col>
            <Col md={6} className="text-center text-md-right">
              <Social space={8} shape="rounded" varient="outlined">
                {twitter && (
                  <SocialLink path={twitter} label="Twitter">
                    <i className="fab fa-twitter"></i>
                  </SocialLink>
                )}
                {facebook && (
                  <SocialLink path={facebook} label="Facebook">
                    <i className="fab fa-facebook-f"></i>
                  </SocialLink>
                )}
                {instagram && (
                  <SocialLink path={instagram} label="Instagram">
                    <i className="fab fa-instagram"></i>
                  </SocialLink>
                )}
                {linkedin && (
                  <SocialLink path={linkedin} label="Linkedin">
                    <i className="fab fa-linkedin"></i>
                  </SocialLink>
                )}
              </Social>
            </Col>
          </Row>
        </Container>
      </FooterBottom>
    </FooterWrap>
  );
};

Footer.propTypes = {
  bgcolor: PropTypes.string,
  reveal: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Footer.defaultProps = {
  bgcolor: "#F8F8F8",
  reveal: "false",
  copyrightStyle: {
    responsive: {
      small: {
        pb: "15px"
      }
    }
  }
};

export default Footer;

import { timeline } from "motion";

export function animation() {
    const draw = (progress) => ({
        // This property makes the line "draw" in when animated
        strokeDashoffset: 1 - progress,

        // Each line will be hidden until it starts drawing
        // to fix a bug in Safari where the line can be
        // partially visible even when progress is at 0
        visibility: "visible"
    });

    const fade = (progress) => ({
        // This property makes the line "draw" in when animated
        opacity: 1 - progress
    });

    const fill = (progress) => ({
        // This property makes the shape "fill" in when animated
        fillOpacity: 0 + progress
    });

    timeline(
        [
            ["path", draw(1), { duration: 3, delay: 0.25 }],
            // ["svg", { rotate: 360 },
            // { easing: spring({damping: 100000}) }],
            ["path", fill(1), { duration: 1, delay: 0.25 }],
            //["path", fade(1), { duration: 0.5, delay: 0.5 }]
        ],
    );
}

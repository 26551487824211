import styled, {css} from 'styled-components';
import {device} from '../../../../theme';

const CheckboxWrap = styled.ul`
    list-style: none;
    width: 320px;
    @media ${device.small}{
        width: 200px;
    }
    @media ${device.medium}{
        width: 320px;
    }
`;

const CheckboxItemWrap = styled.li`
    position: relative;
	width: 100%;
	margin: 0 1% 15px;
	font-size: 16px;
	overflow: hidden;
`;

const CheckboxBg = styled.div`
    position: absolute;
	background: #2e2d37;
	width: 36px;
	height: 36px;
	top: 10px;
	left: 10px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	-webkit-transition: all .3s ease-out;
	transition: all .3s ease-out;
	-webkit-transform:scale(1);
	transform:scale(1);
	z-index: 0;
`;

const CheckboxBody = styled.div`
    height: 300px;
	color: #fff;
	z-index: 2;
	position: relative;
`;

export {CheckboxWrap, CheckboxItemWrap, CheckboxBg, CheckboxBody}
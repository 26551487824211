import styled, { css } from 'styled-components';
import { device } from '../../theme'

export const LogoWrapper = styled.div`
    display: flex;
    justify-content: ${props => props.justifycontent};
    padding-top: ${props => props.pt};
    padding-bottom: ${props => props.pb};
    a{
        img{
            width: 160px;
        }
    }

    path {
        fill: transparent;
        stroke-width: 5px;
        stroke-dasharray: 1;
        stroke-dashoffset: 1;
        stroke-linecap: round;
        stroke-linejoin: round;
        visibility: hidden;
    }

    .st0 {
        fill: rgba(75, 30, 121, 1);
        fill-opacity: 0;
        stroke: rgba(73, 73, 73, 1);
    }

    .st1 {
        fill: rgba(73, 73, 73, 1);
        fill-opacity: 0;
        stroke: rgba(75, 30, 121, 1);
    }

    .st3 {
        fill: #ffffff;
        fill-opacity: 0;
        stroke: rgba(73, 73, 73, 1);
    }

    ${props => props.transparenHeader && css`
        .dark-logo {
            display: none;
        }
        .light-logo {
            display: inherit;
        }
        @media ${device.medium}{
            .dark-logo {
                display: inherit;
            }
            .light-logo {
                display: none;
            }
        }
    `}
    ${props => props.darkLogo && css`
        .dark-logo {
            display: inherit;
        }
        .light-logo {
            display: none;
        }
    `}
`;